

















import { Bind, Debounce } from 'lodash-decorators'
import { Component, Ref } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { MetaInfo } from 'vue-meta'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// components
import PromoBaseForm from '@/components/forms/promo/PromoBaseForm.vue'
import Tag from '@/components/_uikit/Tag.vue'
import UserSocialCard from '@/components/cards/UserSocialCard.vue'
import ManagerPromotionModule from '@/store/modules/manager/offer'
// interfaces
import { PromotionData, PromotionLargeResource } from '@/store/types'
import Confirmation from '@/components/modals/Confirmation.vue'

@Component({
  components: {
    Confirmation,
    PromoBaseForm,
    Tag,
    UserSocialCard,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class PromoEdit extends NotifyMixin {

  @Ref() confirm!: Confirmation

  private get breadcrumbs() {
    return [{ name: 'Промоакции', path: '/manager/commerce/promocodes' }]
  }

  private get promotion(): PromotionLargeResource | null {
    return ManagerPromotionModule.promotion
  }

  private isLoaded = false

  private id = parseInt(this.$route.params.promoId, 10)

  private created () {
    if (!this.promotion || this.promotion.id !== this.id) {
      ManagerPromotionModule.fetchPromotion(this.id)
        .catch((error) => {
          this.notifyError(error)
          this.$router.push({ name: 'manager.commerce.promocodes' })
        })
        .finally(() => this.isLoaded = true)
    } else {
      this.isLoaded = true
    }
  }

  @Debounce(500)
  @Bind
  private handleSubmit (form: PromotionData) {
    ManagerPromotionModule.savePromotion({ params: form, promotionId: this.id })
      .then(() => {
        this.notifySuccess('Промоакция обновлена')
        this.$router.push({ name: 'manager.commerce.promocodes' })
      })
      .catch(this.notifyError)
  }

  private confirmDelete () {
    this.confirm.open(
      'Удаление промоакции',
      'Вы уверены, что хотите удалить промоакцию?',
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(this.handleDelete)
      .catch(() => {return})
  }

  private handleDelete () {
    ManagerPromotionModule.deletePromotion(this.id)
      .then(() => {
        this.notifySuccess('Промоакция была успешно удалена')
        this.$router.push({ name: 'manager.commerce.promocodes' })
      })
      .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Редактирование промоакции',
    }
  }
}
